#mainWrapper {
  width: 700px;
  max-width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#profileImage {
  margin: 20px auto;
  max-width: 100%;
  width: 600px;
  border-radius: 50%;
  justify-content: center;
}

p#aboutDescription {
  text-align: center;
  margin: 10px auto;
  font-size: 18px;
}

p.sectionName {
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 5px;
  margin-top: 15px;
}

div.sectionItem {
  margin-left: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

div#imageWrapper {
  margin: auto;
}

img.itemImage {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 9px;
}

div.itemInformation {
  flex: 1 1 0;
}

p.itemTitle {
  font-size: 20px;
  margin: 0 14px;
}

p.itemDetails {
  margin: 0 14px;
}

a.projectLink {
  text-decoration: none;
  color: rgb(25, 95, 185);
  font-weight: bold;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

a.projectLink:hover {
  color: red;
}

.projectImage {
  margin-top: 9px;
}
