div#mainTitle {
  max-width: 90%;
  margin: auto;
}

p#title {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 48px;
  cursor: default;
  font-weight: bold;
}

@media all and (max-width: 415px) {
  p#title {
    font-size: 45px;
  }
}

@media all and (max-width: 320px) {
  p#title {
    font-size: 40px;
  }
}

p#copyright {
  font-size: 14px;
  text-align: center;
}

div.socialIconWrapper {
  width: 600px;
  max-width: 90%;
  margin-left: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

a.socialIconA {
  margin: auto 5px;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

a.socialIconA:hover {
  opacity: 0.5;
}

div#socialIconWrapper {
  text-align: center;
  margin-bottom: 5px;
}
