  /*
  ** SUN
  */
  @keyframes am-weather-sun {
    0% {
      -webkit-transform: rotate(0deg);
         -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
         -moz-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  .am-weather-sun {
    -webkit-animation-name: am-weather-sun;
       -moz-animation-name: am-weather-sun;
        -ms-animation-name: am-weather-sun;
            animation-name: am-weather-sun;
    -webkit-animation-duration: 9s;
       -moz-animation-duration: 9s;
        -ms-animation-duration: 9s;
            animation-duration: 9s;
    -webkit-animation-timing-function: linear;
       -moz-animation-timing-function: linear;
        -ms-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
       -moz-animation-iteration-count: infinite;
        -ms-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  
  @keyframes am-weather-sun-shiny {
    0% {
      stroke-dasharray: 3px 10px;
      stroke-dashoffset: 0px;
    }
  
    50% {
      stroke-dasharray: 0.1px 10px;
      stroke-dashoffset: -1px;
    }
  
    100% {
      stroke-dasharray: 3px 10px;
      stroke-dashoffset: 0px;
    }
  }
  
  .am-weather-sun-shiny line {
    -webkit-animation-name: am-weather-sun-shiny;
       -moz-animation-name: am-weather-sun-shiny;
        -ms-animation-name: am-weather-sun-shiny;
            animation-name: am-weather-sun-shiny;
    -webkit-animation-duration: 2s;
       -moz-animation-duration: 2s;
        -ms-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: linear;
       -moz-animation-timing-function: linear;
        -ms-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
       -moz-animation-iteration-count: infinite;
        -ms-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
    
  /*
  ** EASING
  */
  .am-weather-easing-ease-in-out {
    -webkit-animation-timing-function: ease-in-out;
       -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }