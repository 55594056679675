div#navBar {
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 460px;
    width: 90%;
    margin: auto;
}

a.navLink {
    text-decoration: none;
    margin-left: 4px;
    margin-right: 4px;
    color: black;
    font-size: 22px;
    -webkit-transition: .2s ease ;
    -moz-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
}

a.navLink:hover {
    color: red;
}

@media all and (max-width: 330px) {
    a.navLink {
        font-size: 18px;
    }
}